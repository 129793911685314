import React from "react";

export default function Header() {
  const handleNavigte = () => {
    window.scrollTo(0, 0);
  };
  return (
    <div
      className="fixed right-0 left-0 z-50 "
      style={{
        background: "rgba(5, 141, 141, 0.00)",
        WebkitBackdropFilter: "blur(10px)",
        backdropFilter: "blur(10px)",
      }}
    >
      <div className="max-w-[1660px] w-[100%] m-auto">
        <div className="h-[95px] flex items-center justify-between w-[100%] sm:pl-[50px]  sm:pr-[35px] px-[20px] ">
          <img
            src="/Frame 2.png"
            alt=""
            className="sm:w-[168px] sm:h-[70px] w-[100px] h-[45px]  cursor-pointer"
            onClick={handleNavigte}
          />
          <div
            className=" mx-[20px] px-[20px] w-[350px]   h-[37px]   sm:flex hidden items-center justify-around text-white "
            // style={{
            //   border: "1px solid rgba(18, 32, 32, 0.52)",
            //   background:
            //     "linear-gradient(91deg, rgba(159, 241, 241, 0.10) -42.26%, rgba(226, 254, 254, 0.10) 135.2%)",
            // }}
            style={{
              backgroundImage: "url(/some.png)",
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
            }}
          >
            <a href="#service">
              <p className="cursor-pointer hover:text-[#109697] text-[14px]">
                Services
              </p>
            </a>
            <a href="#work">
              <p className="my-work cursor-pointer text-[14px] border-l border-r py-[2px] border-[#bbfcfc] px-[32px] hover:text-[#109697]">
                Work
              </p>
            </a>
            <a href="#AboutUS">
              <p className="cursor-pointer hover:text-[#109697] text-[14px]">
                Team
              </p>
            </a>
          </div>
          <div>
            <a href="#form">
              <img
                src="/Frame 1.png"
                alt=""
                className="cursor-pointer h-[50px] rounded-[20px]"
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
