import Header from "./components/header";
import { useEffect, useState } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Footer from "./components/footer";
import About from "./components/about";
import Work from "./components/work";
import Services from "./components/services";
import Slider from "react-slick";

export default function Cover() {
  const [scrollPercentage, setScrollPercentage] = useState(0);
  const handleScroll = () => {
    const scrollableHeight =
      document.documentElement.scrollHeight - window.innerHeight;
    const scrolledHeight = window.scrollY;
    const percentage = (scrolledHeight / scrollableHeight) * 100;
    setScrollPercentage(percentage);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  const settings = {
    dots: true,
    arrows: false,

    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
  };

  return (
    <div id="particles-js" className="relative">
      <div className="" style={{ height: "950px", width: "100%" }}></div>
      <div className="absolute top-0 left-0 right-0 w-[100%] sm:h-[1000px] h-[200px]">
        <Header />
        <div className=" sm:mt-[250px] mt-[180px] w-[100%] h-[100%]  ">
          <div className="max-w-[1270px] text-start w-[100%] m-auto text-white xl:text-[85px] lg:text-[65px] sm:text-[40px] text-[31px] myfont md:px-[100px] sm:px-[50px] px-[20px]  lg:leading-[100px] md:leading-[60px] sm:leading-[50px] leading-[45px]">
            <p>
              <span>
                <span
                  className="w-fit px-3 sm:rounded-[30px] rounded-[20px]"
                  style={{
                    background:
                      "linear-gradient(96deg, rgba(33, 63, 63, 0.40) 6.8%, rgba(217, 217, 217, 0.10) 91.05%)",
                    backdropFilter: "blur(2px)",
                    border: "1px solid #B4CBCB",
                  }}
                >
                  <span className="we">We</span>
                </span>
              </span>{" "}
              are far from average; our team can expertly{" "}
            </p>
            <div
              className="mt-2 sm:pb-4 pb-2 w-fit  rounded-[30px]"
              style={{
                background:
                  "linear-gradient(97deg, rgba(33, 63, 63, 0.40) 24.17%, rgba(217, 217, 217, 0.00) 77.31%)",
                backdropFilter: "blur(10px)",
                border: "1px solid #B4CBCB",
              }}
            >
              <p className="px-4  w-fit design-company"> design companies.</p>
            </div>
          </div>
          <div className="flex justify-center sm:mt-[70px] mt-[40px]">
            <a href="#form">
              <img src="/Frame 4.svg" alt="" />
            </a>
          </div>

          <div
            className=" w-[50px] py-3 h-[80px] text-[16px] rounded-full font-semibold m-auto sm:mt-[58px] mt-[38px] flex justify-center"
            style={{
              border: "1px solid rgba(180, 203, 203, 0.38)",
              backgroundImage:
                "linear-gradient(96deg, rgba(33, 63, 63, 0.40) 6.8%, rgba(217, 217, 217, 0.10) 91.05%)",
            }}
          >
            <div
              className="h-[10px] w-[10px] rounded-full bg-white "
              style={{ marginTop: `${scrollPercentage.toFixed(0)}%` }}
            ></div>
          </div>
        </div>
      </div>
      <div id="service" className="absolute  w-full     text-white">
        <Services />
        <div
          style={{
            background:
              "linear-gradient(95deg, #89D4E4 0%, #4EC6CB 35.42%, #12A7BC 61.35%, #068096 100%)",
          }}
        >
          <div className="max-w-[1600px] m-auto pt-[40px] lg:pb-[80px] pb-[54px] xl:pl-[153px] lg:pl-[100px] sm:pl-[50px] pl-[20px]">
            <p className="text-[#09899F] font-bold 2xl:text-[20px] text-[16px] myfont">
              LET’S WORK TOGETHER
            </p>
            <p className="myfont 2xl:text-[50px] mt-1 lg:text-[38px] text-[30px] text-black lg:leading-10 leading-8 ">
              Didn’t see what you were looking for?
            </p>
            <div className="xl:pr-[200px] mt-2 sm:pr-[100px] pr-[20px] 2xl:text-[25px] text-[15px] text-[#000] font-[300] 2xl:leading-normal xl:leading-5 leading-[18px]">
              <p>
                At Sr codes we understand that every vision is unique, and not
                every web solution fits into a predefined category. That's where
                our exceptional team of talented designers and developers
                excels. We're your partners in bringing your custom ideas to
                life.
                <br />
                <p className="mt-2">
                  Unleash your imagination and let us craft a solution that's
                  tailored to your exact needs. We're ready to listen,
                  brainstorm, and create something extraordinary.
                </p>
              </p>
            </div>
            <div className="xl:pr-[200px] sm:pr-[100px] pr-[20px] mt-[30px] sm:flex justify-between items-center">
              <div
                className="cursor-pointer 2xl:h-[50px] h-[40px] rounded-[18px] xl:w-[500px] lg:w-[450px] sm:w-[350px] w-[100%] flex justify-center items-center sm:mr-[30px]"
                style={{
                  border: "1px solid rgba(18, 32, 32, 0.45)",
                  backgroundImage:
                    "linear-gradient(82deg, #092D2D 0.33%, rgba(10, 83, 83, 0.58) 100.33%)",
                  boxShadow:
                    "1px 0px 4px 0px rgba(51, 250, 250, 0.70), 2px 1px 7px 0px rgba(51, 250, 250, 0.50)",
                }}
                onClick={() => {
                  // Replace 'your-link-here' with the actual link you want to open in the new tab
                  window.open(
                    "https://calendly.com/thedigiauthor/30min",
                    "_blank"
                  );
                }}
              >
                <p className="2xl:text-[20px] font-bold">
                  Book a 30-Minute Call
                </p>
              </div>
              <a href="#form">
                <div
                  className="cursor-pointer 2xl:h-[50px] h-[40px] rounded-[18px]  sm:mt-[0] mt-[20px]  xl:w-[500px] lg:w-[450px] sm:w-[350px] w-[100%] flex justify-center items-center"
                  style={{
                    border: "1px solid rgba(18, 32, 32, 0.45)",
                    backgroundImage:
                      "linear-gradient(82deg, #092D2D 0.33%, rgba(10, 83, 83, 0.58) 100.33%)",
                    boxShadow:
                      "1px 0px 4px 0px rgba(51, 250, 250, 0.70), 2px 1px 7px 0px rgba(51, 250, 250, 0.50)",
                  }}
                >
                  <p className="2xl:text-[20px]  font-bold">Text Us</p>
                </div>
              </a>
            </div>
          </div>
        </div>
        <div
          id="work"
          className="xl:px-[70px] px-[20px] sm:py-[130px] py-[60px] mt-[-10px] "
          style={{ backgroundImage: "url(pexel.png)" }}
        >
          <div className="max-w-[1600px] w-[100%] m-auto">
            <p className="font-bold text-[30px]   text-[#122020] sm:pl-[70px] pl-[20px]">
              Our Work
            </p>
            <Slider {...settings}>
              {[1, 2, 3].map((item) => (
                <div className="sm:py-[30px] py-[10px] sm:px-5 px-2">
                  <Work />
                </div>
              ))}
            </Slider>
          </div>
        </div>
        <About />
        <Footer />
      </div>
    </div>
  );
}
