import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import emailjs from "emailjs-com";

export default function Footer() {
  const [isPopupVisible, setPopupVisible] = useState(false);

  const handleOpenPopup = () => {
    setPopupVisible(true);
  };

  const handleClosePopup = () => {
    setPopupVisible(false);
  };
  useEffect(() => emailjs.init("TiXavJkyZ8nscRmVD"), []);
  const validationSchema = Yup.object({
    name: Yup.string().required("Name is required"),
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
    message: Yup.string().required("Message is required"),
  });

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      company: "",
      phone: "",
      message: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values, { resetForm }) => {
      // Handle form submission logic here
      sendEmail(values);
      handleOpenPopup();
      resetForm();
    },
  });
  const sendEmail = (values) => {
    console.log(values);

    const templateParams = {
      to_name: "Shivam",
      from_name: values?.name,
      email: values?.email,
      message: values?.message,
      phone_number: values?.phone,
      company_name: values?.company,
    };

    emailjs
      .send("service_j6hwqeu", "template_pvkmw29", templateParams)
      .then((response) => {
        console.log("Email sent successfully:", response);
      })
      .catch((error) => {
        console.error("Email sending failed:", error);
      });
  };
  return (
    <div id="form">
      <div
        className="sm:pt-[200px] pt-[50px] sm:pb-[200px] pb-[100px]"
        style={{
          background:
            "linear-gradient(to right bottom, black 50%, #252a2a 20.3%)",
        }}
      >
        <div className="max-w-[1100px] w-[100%] px-[30px] m-auto flex md:flex-row flex-col items-center">
          <div className="md:block hidden">
            <div className="flex">
              <img src="/icons8-call-64 1.svg" alt="" />
              <p className="text-[16px] font-bold">Call us</p>
            </div>
            <a href="tel:+18257770777" className="font-bold cursor-pointer">
              +1 (825) 777 0777
            </a>
            <div className="flex mt-8">
              <img src="/icons8-email-80 1.svg" alt="" />
              <p className="text-[16px] font-bold ml-1">Email Us</p>
            </div>
            <p className="font-bold">
              {" "}
              <a href="mailto:info@thedigiauthor.com">info@thedigiauthor.com</a>
            </p>
            <div className="flex mt-8">
              <img src="/icons8-clock-50 1.svg" alt="" />
              <p className="text-[16px] font-bold ml-1">Business Hours</p>
            </div>
            <p className="font-bold">Mon - Sat 10am - 8pm</p>
          </div>
          <form onSubmit={formik.handleSubmit}>
            <div
              className="xl:ml-[267px] lg:ml-[150px] md:ml-[100px] md:mt-0 lg:max-w-[600px] max-w-[400px] w-[100%] bg-[#7DE6E6] py-[25px] px-[26px] rounded-[15px]"
              style={{
                boxShadow:
                  "4px 4px 11.1px 0px rgba(125, 230, 230, 0.50), 0px 4px 4px 0px rgba(125, 230, 230, 0.42)",
              }}
            >
              <p className="text-[25px] text-black">Contact us</p>
              <div className="grid grid-cols-2 gap-8 mt-[20px]">
                <div>
                  <input
                    type="text"
                    placeholder=" Name"
                    className="border-b border-t-0 border-l-0 brder-r-0 border-[#122020] w-[90%]  text-black bg-[#7DE6E6] !rounded-[0px] focus-visible:outline-none"
                    name="name"
                    value={formik.values.name}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.touched.name && formik.errors.name && (
                    <div className="text-red-500 text-[12px] absolute">
                      {formik.errors.name}
                    </div>
                  )}

                  <input
                    type="text"
                    placeholder=" Email "
                    className="border-b border-t-0 border-l-0 brder-r-0 border-[#122020]  w-[90%] text-black bg-[#7DE6E6] !rounded-[0px] focus-visible:outline-none mt-[50px]"
                    name="email"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.touched.email && formik.errors.email && (
                    <div className="text-red-500 text-[12px] absolute">
                      {formik.errors.email}
                    </div>
                  )}
                </div>
                <div>
                  <input
                    type="text"
                    placeholder=" Company"
                    className="border-b border-t-0 border-l-0 brder-r-0 border-[#122020] w-[90%] bg-[#7DE6E6] text-black !rounded-[0px]  focus-visible:outline-none"
                    name="company"
                    value={formik.values.company}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.touched.company && formik.errors.company && (
                    <div className="text-red-500 text-[12px] absolute">
                      {formik.errors.company}
                    </div>
                  )}

                  <input
                    type="text" // Use type="text" instead of type="number"
                    inputMode="numeric" // Set input mode to "numeric"
                    placeholder="Phone"
                    className="border-b border-t-0 border-l-0 brder-r-0 border-[#122020] w-[90%] bg-[#7DE6E6] text-black !rounded-[0px]  focus-visible:outline-none mt-[50px]"
                    name="phone"
                    value={formik.values.phone}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    style={{ MozAppearance: "textfield" }}
                  />

                  {formik.touched.phone && formik.errors.phone && (
                    <div className="text-red-500 text-[12px] absolute">
                      {formik.errors.phone}
                    </div>
                  )}
                </div>
              </div>
              <div className="">
                <textarea
                  placeholder="Message"
                  className="h-[70px] border-t-0 border-l-0 brder-r-0 focus-visible:outline-none w-[100%] text-black bg-[#7DE6E6] !rounded-[0px] border-b border-[#122020] mt-[50px]"
                  style={{ resize: "none" }}
                  name="message"
                  value={formik.values.message}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.touched.message && formik.errors.message && (
                  <div className="text-red-500 text-[12px] absolute">
                    {formik.errors.message}
                  </div>
                )}
              </div>
              <button
                type="submit"
                className="cursor-pointer rounded-[18px] mt-[30px] h-[50px] w-full flex justify-center items-center text-black font-bold text-[20px]"
                style={{
                  border: "1px solid rgba(18, 32, 32, 0.45)",
                  backgroundImage:
                    "linear-gradient(82deg, #092D2D 0.33%, rgba(10, 83, 83, 0.58) 100.33%)",
                  boxShadow:
                    "1px 0px 4px 0px rgba(51, 250, 250, 0.70), 2px 1px 7px 0px rgba(51, 250, 250, 0.50)",
                }}
              >
                Send
              </button>
            </div>
          </form>
          <div className="md:hidden mt-10">
            <div className="flex">
              <img src="/icons8-call-64 1.svg" alt="" />
              <p className="text-[16px] font-bold">Call us</p>
            </div>
            <a href="tel:+18257770777" className="font-bold cursor-pointer">
              +1 (825) 777 0777
            </a>
            <div className="flex mt-8">
              <img src="/icons8-email-80 1.svg" alt="" />
              <p className="text-[16px] font-bold ml-1">Email Us</p>
            </div>
            <p className="font-bold">
              {" "}
              <a href="mailto:info@thedigiauthor.com">info@thedigiauthor.com</a>
            </p>
            <div className="flex mt-8">
              <img src="/icons8-clock-50 1.svg" alt="" />
              <p className="text-[16px] font-bold ml-1">Business Hours</p>
            </div>
            <p className="font-bold">Mon - Sat 10am - 8pm</p>
          </div>
        </div>
      </div>
      {isPopupVisible && (
        <div className="fixed inset-0 flex items-center justify-center">
          <div className="fixed inset-0 bg-gray-800 opacity-90"></div>
          <div
            style={{
              background:
                "linear-gradient(95deg, #89D4E4 0%, #4EC6CB 35.42%, #12A7BC 61.35%, #068096 100%)",
            }}
            className=" p-4 rounded shadow-md relative w-[300px] text-black"
          >
            <img src="/check1.png" alt="" className="m-auto w-[100px]" />
            <p className="text-center font-bold">
              your email sent successfully
            </p>

            <button
              className="mt-2  font-bold py-2 px-4 rounded w-[100%] text-white"
              onClick={handleClosePopup}
              style={{
                border: "1px solid rgba(18, 32, 32, 0.45)",
                backgroundImage:
                  "linear-gradient(82deg, #092D2D 0.33%, rgba(10, 83, 83, 0.58) 100.33%)",
                boxShadow:
                  "1px 0px 4px 0px rgba(51, 250, 250, 0.70), 2px 1px 7px 0px rgba(51, 250, 250, 0.50)",
              }}
            >
              Ok
            </button>
          </div>
        </div>
      )}
    </div>
  );
}
