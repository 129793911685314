import React from "react";

export default function Work() {
  return (
    <div>
      <div
        className="rounded-[50px] sm:pb-[83px] pb-[43px] sm:pt-[37px] lg:pr-[75px] 2xl:pl-[31px] pl-[11px] sm:mt-[20px]"
        style={{
          background:
            "linear-gradient(276deg, rgba(160, 248, 248, 0.05) 1.94%, rgba(84, 90, 90, 0.09) 96.31%)",
          boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
          backdropFilter: "blur(10px)",
        }}
      >
        <div className="flex md:flex-row flex-col justify-between items-center">
          <div className="2xl:w-[670px] md:w-[500px]">
            <img src="/image 3.svg" alt="" className="md:m-0 m-auto" />
          </div>
          <div className="2xl:w-[600px] md:w-[500px] md:pl-[50px] xl:pr-[100px] lg:pr-[50px] sm:px-0 px-[20px]">
            <p
              className="text-center myfont text-[25px] font-[900]"
              style={{ color: "rgba(18, 32, 32, 0.92)" }}
            >
              9 on 9
            </p>
            <p
              className="2xl:text-[25px] lg:text-[20px] text-[14px] font-medium mt-[30px] lg:leading-[30px] leading-[20px]"
              style={{ color: "rgba(18, 32, 32, 0.92)" }}
            >
              Not missing a single detail, our teams ensure that both front-end
              and back-end issues are properly addressed for each of the
              delivered web applications.
              <br />
              <br />
              Not missing a single detail, our teams ensure that both front-end
              and back-end issues are properly addressed for each of the
              delivered web applications.
            </p>
            <div className="flex items-center mt-3">
              <img
                src="shopify 1.svg"
                alt=""
                className="sm:w-[44px] sm:h-[50px] w-[24px] h-[23px]"
              />
              <img
                src="wordpress.svg"
                alt=""
                className="sm:w-[44px]  sm:h-[43px] w-[24px] h-[23px] sm:ml-[20px] ml-[10px]"
              />
              <img
                src="react.svg"
                alt=""
                className="sm:w-[44px] sm:h-[50px] w-[24px] h-[23px] sm:ml-[20px] ml-[10px]"
              />
              <img
                src="google.svg"
                alt=""
                className="sm:w-[44px] sm:h-[54px] w-[24px] h-[23px] sm:ml-[20px] ml-[10px]"
              />
              <img
                src="Group 12.svg"
                alt=""
                className="sm:w-[44px] sm:h-[42px] w-[24px] h-[23px] sm:ml-[20px] ml-[10px]"
              />
            </div>
          </div>
        </div>
        <div className="flex md:justify-end md:pl-0 pl-[20px]">
          <div
            className=" w-[148px] h-[50px] rounded-[18px] flex justify-center items-center  text-[20px] font-semibold mt-[10px]"
            style={{
              backgroundImage:
                "linear-gradient(82deg, #092D2D 0.33%, rgba(10, 83, 83, 0.17) 100.33%)",
              boxShadow:
                "1px 0px 4px 0px rgba(51, 250, 250, 0.70), 2px 1px 7px 0px rgba(51, 250, 250, 0.50)",
            }}
          >
            Live Website
          </div>
        </div>
      </div>
    </div>
  );
}
