import React, { useState } from "react";
import Zoom from "react-reveal/Zoom";
import Slider from "react-slick";
import ServicesSlider from "../servicesSlider";

export default function Services() {
  const [selectedIndex, setSelectedIndex] = useState("Web Development");
  const [show, setShow] = useState(true);

  const handleClick = (index) => {
    if (selectedIndex !== index) {
      setShow(false);

      setTimeout(() => {
        setShow(true);
        setSelectedIndex(index);
      }, 500);
    }
  };
  const settings = {
    dots: true,
    arrows: false,

    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
  };
  return (
    <div>
      <div
        className="pb-[60px] md:pt-[149px] sm:pt-[100px] pt-[50px] sm:block hidden "
        style={{
          backgroundImage: "url(/group.png)",
          backgroundSize: "cover",
        }}
      >
        <div className="max-w-[1600px] w-[100%] m-auto">
          <p className="md:pl-[107px] md:text-start text-center text-[#058D8D] myfont font-bold text-[30px]">
            Our Services
          </p>
          <p className="lg:pl-[107px] sm:pl-[50px] lg:pr-[100px] sm:pr-[26px] pl-[20px] pr-[20px]  myfont font-medium 2xl:text-[50px] xl:text-[40px] sm:text-[40px] text-[20px] xl:leading-[55px] sm:leading-[55px] leading-[35px] mt-5">
            Take Advantage Of Our Custom Web & Mobile Development Services
          </p>

          <div className="xl:pl-[74px] pl-[20px] lg:pr-[37px] pr-[20px] 2xl:mt-[94px] mt-[30px] flex md:flex-row flex-col items-center justify-between w-full">
            <div
              className="rounded-[50px]  p-[27px] xl:w-[605px] w-[405px] md:block hidden "
              style={{
                boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
                background:
                  "linear-gradient(276deg, rgba(160, 248, 248, 0.05) 1.94%, rgba(84, 90, 90, 0.09) 96.31%)",
                backdropFilter: "blur(6px)",
              }}
            >
              <p
                onClick={() => handleClick("Web Development")}
                className={`${
                  selectedIndex === "Web Development"
                    ? "text-[#058D8D] font-bold 2xl:text-[38px] xl:text-[30px]  text-[20px]"
                    : "text-white 2xl:text-[33px] xl:text-[25px]  text-[15px]"
                }    2xl:text-[38px] xl:text-[30px]  text-[20px]   cursor-pointer`}
              >
                Web Development
              </p>
              <p
                onClick={() => handleClick("Ui / Ux designer")}
                className={`${
                  selectedIndex === "Ui / Ux designer"
                    ? "text-[#058D8D] font-bold 2xl:text-[38px] xl:text-[30px]  text-[20px]"
                    : "text-white 2xl:text-[33px] xl:text-[25px]  text-[15px]"
                }  2xl:text-[38px] xl:text-[30px] text-[20px]  2xl:mt-[38px] mt-[15px] cursor-pointer`}
              >
                Ui / Ux designer
              </p>
              <p
                onClick={() => handleClick("SEO Research")}
                className={`${
                  selectedIndex === "SEO Research"
                    ? "text-[#058D8D] font-bold 2xl:text-[38px] xl:text-[30px]  text-[20px]"
                    : "text-white 2xl:text-[33px] xl:text-[25px]  text-[15px]"
                }  2xl:text-[38px] xl:text-[30px] text-[20px]  2xl:mt-[38px] mt-[15px] cursor-pointer`}
              >
                SEO Research
              </p>
              <p
                onClick={() => handleClick("Copy writing")}
                className={`${
                  selectedIndex === "Copy writing"
                    ? "text-[#058D8D] font-bold 2xl:text-[38px] xl:text-[30px]  text-[20px]"
                    : "text-white 2xl:text-[33px] xl:text-[25px]  text-[15px]"
                }  2xl:text-[38px] xl:text-[30px] text-[20px] 2xl:mt-[38px] mt-[15px] cursor-pointer`}
              >
                Copy writing
              </p>
            </div>
            <div className="sm:hidden grid grid-cols-1">
              <Slider {...settings} className="my_slider">
                {[
                  "Web Development",
                  "Ui / Ux designer",
                  "SEO Research",
                  "Copy writing",
                ].map((item) => (
                  <div>
                    <div
                      className="px-[20px] py-[20px]"
                      style={{
                        boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
                        background:
                          "linear-gradient(276deg, rgba(160, 248, 248, 0.05) 1.94%, rgba(84, 90, 90, 0.09) 96.31%)",
                        backdropFilter: "blur(6px)",
                      }}
                    >
                      <ServicesSlider item={item} />
                    </div>
                  </div>
                ))}
              </Slider>
            </div>
            <div
              className="sm:block hidden rounded-[50px] border border-[#A9CACA] 2xl:px-[53px] 2xl:pt-[37px] pt-[27px] pb-[45px] lg:px-[40px] px-[20px] w-fit 2xl:ml-[103px] xl:ml-[50px] md:ml-[20px]"
              style={{
                boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
                background:
                  "linear-gradient(276deg, rgba(160, 248, 248, 0.05) 1.94%, rgba(84, 90, 90, 0.09) 96.31%)",
                backdropFilter: "blur(6px)",
              }}
            >
              <Zoom when={show} duration={800}>
                {selectedIndex === "Web Development" ? (
                  <div className="grid sm:grid-cols-2  gap-8">
                    <div className="sm:hidden ">
                      <img
                        src="/under.png"
                        alt=""
                        className="h-[150px] w-[200px] m-auto"
                      />
                    </div>
                    <div>
                      <p className="text-white xl:text-[18px] text-[16px]  myfont font-[300] mt-[20px]">
                        Not missing a single detail, our teams ensure that both
                        front-end and back-end issues are properly addressed for
                        each of the delivered web applications.
                        <br />
                        <br /> Not missing a single detail, our teams ensure
                        that both front-end and back-end issues are properly
                        addressed for each of the delivered web applications.
                      </p>
                    </div>
                    <div className="sm:block hidden">
                      <img src="/under.png" alt="" />
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </Zoom>

              <Zoom when={show} duration={800}>
                {selectedIndex === "Ui / Ux designer" ? (
                  <div className="grid sm:grid-cols-2  gap-8">
                    <div className="sm:hidden ">
                      <img
                        src="/under.png"
                        alt=""
                        className="h-[150px] w-[200px] m-auto"
                      />
                    </div>
                    <div>
                      <p className="text-white xl:text-[18px] text-[16px]   myfont font-[300] mt-[20px]">
                        Not missing a single detail, our teams ensure that both
                        front-end and back-end issues are properly addressed for
                        each of the delivered web applications.
                        <br />
                        <br /> Not missing a single detail, our teams ensure
                        that both front-end and back-end issues are properly
                        addressed for each of the delivered web applications.
                      </p>
                    </div>
                    <div className="sm:block hidden">
                      <img src="/under.png" alt="" />
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </Zoom>
              <Zoom when={show} duration={800}>
                {selectedIndex === "SEO Research" ? (
                  <div className="grid sm:grid-cols-2  gap-8">
                    <div className="sm:hidden ">
                      <img
                        src="/under.png"
                        alt=""
                        className="h-[150px] w-[200px] m-auto"
                      />
                    </div>
                    <div>
                      <p className="text-white xl:text-[18px] text-[16px]  myfont font-[300] mt-[20px]">
                        Not missing a single detail, our teams ensure that both
                        front-end and back-end issues are properly addressed for
                        each of the delivered web applications.
                        <br />
                        <br /> Not missing a single detail, our teams ensure
                        that both front-end and back-end issues are properly
                        addressed for each of the delivered web applications.
                      </p>
                    </div>
                    <div className="sm:block hidden">
                      <img src="/under.png" alt="" />
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </Zoom>
              <Zoom when={show} duration={800}>
                {selectedIndex === "Copy writing" ? (
                  <div className="grid sm:grid-cols-2  gap-8">
                    <div className="sm:hidden ">
                      <img
                        src="/under.png"
                        alt=""
                        className="h-[150px] w-[200px] m-auto"
                      />
                    </div>
                    <div>
                      <p className="text-white xl:text-[18px] text-[16px]  myfont font-[300] mt-[20px]">
                        Not missing a single detail, our teams ensure that both
                        front-end and back-end issues are properly addressed for
                        each of the delivered web applications.
                        <br />
                        <br /> Not missing a single detail, our teams ensure
                        that both front-end and back-end issues are properly
                        addressed for each of the delivered web applications.
                      </p>
                    </div>
                    <div className="sm:block hidden">
                      <img src="/under.png" alt="" />
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </Zoom>
              <div className="flex justify-center items-center mt-[39px]">
                <div
                  className={`w-[10px] h-[10px] ${
                    selectedIndex === "Web Development"
                      ? "bg-[#7DE6E6]"
                      : "bg-white"
                  }  rounded-full `}
                />
                <div
                  className={`w-[10px] h-[10px] ${
                    selectedIndex === "Ui / Ux designer"
                      ? "bg-[#7DE6E6]"
                      : "bg-white"
                  }  rounded-full ml-[19px]`}
                />
                <div
                  className={`w-[10px] h-[10px] ${
                    selectedIndex === "SEO Research"
                      ? "bg-[#7DE6E6]"
                      : "bg-white"
                  }  rounded-full ml-[19px]`}
                />
                <div
                  className={`w-[10px] h-[10px] ${
                    selectedIndex === "Copy writing"
                      ? "bg-[#7DE6E6]"
                      : "bg-white"
                  }  rounded-full ml-[19px]`}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="pb-[60px] md:pt-[149px] bg-black sm:pt-[100px] pt-[50px] sm:hidden ">
        <div className="max-w-[1600px] w-[100%] m-auto">
          <p className="md:pl-[107px] md:text-start text-center text-[#058D8D] myfont font-bold text-[30px]">
            Our Services
          </p>
          <p className="lg:pl-[107px] sm:pl-[50px] lg:pr-[46px] sm:pr-[26px] pl-[20px] pr-[20px]  myfont font-bold 2xl:text-[70px] xl:text-[45px] sm:text-[40px] text-[20px] xl:leading-[65px] sm:leading-[55px] leading-[35px] mt-5">
            Take Advantage Of Our Custom Web & Mobile Development Services
          </p>

          <div className="xl:pl-[74px] pl-[20px] lg:pr-[37px] pr-[20px] 2xl:mt-[94px] mt-[30px] flex md:flex-row flex-col items-center justify-between w-full">
            <div
              className="rounded-[50px]  p-[27px] xl:w-[605px] w-[405px] md:block hidden "
              style={{
                boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
                background:
                  "linear-gradient(276deg, rgba(160, 248, 248, 0.05) 1.94%, rgba(84, 90, 90, 0.09) 96.31%)",
                backdropFilter: "blur(6px)",
              }}
            >
              <p
                onClick={() => handleClick("Web Development")}
                className={`${
                  selectedIndex === "Web Development"
                    ? "text-[#058D8D] font-bold 2xl:text-[38px] xl:text-[30px]  text-[20px]"
                    : "text-white 2xl:text-[33px] xl:text-[25px]  text-[15px]"
                }    2xl:text-[38px] xl:text-[30px]  text-[20px] myfont  cursor-pointer`}
              >
                Web Development
              </p>
              <p
                onClick={() => handleClick("Ui / Ux designer")}
                className={`${
                  selectedIndex === "Ui / Ux designer"
                    ? "text-[#058D8D] font-bold 2xl:text-[38px] xl:text-[30px]  text-[20px]"
                    : "text-white 2xl:text-[33px] xl:text-[25px]  text-[15px]"
                }  2xl:text-[38px] xl:text-[30px] text-[20px] myfont 2xl:mt-[38px] mt-[15px] cursor-pointer`}
              >
                Ui / Ux designer
              </p>
              <p
                onClick={() => handleClick("SEO Research")}
                className={`${
                  selectedIndex === "SEO Research"
                    ? "text-[#058D8D] font-bold 2xl:text-[38px] xl:text-[30px]  text-[20px]"
                    : "text-white 2xl:text-[33px] xl:text-[25px]  text-[15px]"
                }  2xl:text-[38px] xl:text-[30px] text-[20px] myfont 2xl:mt-[38px] mt-[15px] cursor-pointer`}
              >
                SEO Research
              </p>
              <p
                onClick={() => handleClick("Copy writing")}
                className={`${
                  selectedIndex === "Copy writing"
                    ? "text-[#058D8D] font-bold 2xl:text-[38px] xl:text-[30px]  text-[20px]"
                    : "text-white 2xl:text-[33px] xl:text-[25px]  text-[15px]"
                }  2xl:text-[38px] xl:text-[30px] text-[20px] myfont 2xl:mt-[38px] mt-[15px] cursor-pointer`}
              >
                Copy writing
              </p>
            </div>
            <div className="sm:hidden grid grid-cols-1">
              <Slider {...settings} className="my_slider">
                {[
                  "Web Development",
                  "Ui / Ux designer",
                  "SEO Research",
                  "Copy writing",
                ].map((item) => (
                  <div>
                    <div
                      className="px-[20px] py-[20px]"
                      style={{
                        boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
                        background:
                          "linear-gradient(276deg, rgba(160, 248, 248, 0.05) 1.94%, rgba(84, 90, 90, 0.09) 96.31%)",
                        backdropFilter: "blur(6px)",
                      }}
                    >
                      <ServicesSlider item={item} />
                    </div>
                  </div>
                ))}
              </Slider>
            </div>
            <div
              className="sm:block hidden rounded-[50px] border border-[#A9CACA] 2xl:px-[53px] 2xl:pt-[37px] pt-[27px] pb-[45px] lg:px-[40px] px-[20px] w-fit 2xl:ml-[103px] xl:ml-[50px] md:ml-[20px]"
              style={{
                boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
                background:
                  "linear-gradient(276deg, rgba(160, 248, 248, 0.05) 1.94%, rgba(84, 90, 90, 0.09) 96.31%)",
                backdropFilter: "blur(6px)",
              }}
            >
              <Zoom when={show} duration={800}>
                {selectedIndex === "Web Development" ? (
                  <div className="grid sm:grid-cols-2  gap-8">
                    <div className="sm:hidden ">
                      <img
                        src="/under.png"
                        alt=""
                        className="h-[150px] w-[200px] m-auto"
                      />
                    </div>
                    <div>
                      <p className="text-white xl:text-[18px] text-[16px]  myfont font-bold mt-[20px]">
                        Not missing a single detail, our teams ensure that both
                        front-end and back-end issues are properly addressed for
                        each of the delivered web applications.
                        <br />
                        <br /> Not missing a single detail, our teams ensure
                        that both front-end and back-end issues are properly
                        addressed for each of the delivered web applications.
                      </p>
                    </div>
                    <div className="sm:block hidden">
                      <img src="/under.png" alt="" />
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </Zoom>

              <Zoom when={show} duration={800}>
                {selectedIndex === "Ui / Ux designer" ? (
                  <div className="grid sm:grid-cols-2  gap-8">
                    <div className="sm:hidden ">
                      <img
                        src="/under.png"
                        alt=""
                        className="h-[150px] w-[200px] m-auto"
                      />
                    </div>
                    <div>
                      <p className="text-white xl:text-[18px] text-[16px]   myfont font-bold mt-[20px]">
                        Not missing a single detail, our teams ensure that both
                        front-end and back-end issues are properly addressed for
                        each of the delivered web applications.
                        <br />
                        <br /> Not missing a single detail, our teams ensure
                        that both front-end and back-end issues are properly
                        addressed for each of the delivered web applications.
                      </p>
                    </div>
                    <div className="sm:block hidden">
                      <img src="/under.png" alt="" />
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </Zoom>
              <Zoom when={show} duration={800}>
                {selectedIndex === "SEO Research" ? (
                  <div className="grid sm:grid-cols-2  gap-8">
                    <div className="sm:hidden ">
                      <img
                        src="/under.png"
                        alt=""
                        className="h-[150px] w-[200px] m-auto"
                      />
                    </div>
                    <div>
                      <p className="text-white xl:text-[18px] text-[16px]  myfont font-bold mt-[20px]">
                        Not missing a single detail, our teams ensure that both
                        front-end and back-end issues are properly addressed for
                        each of the delivered web applications.
                        <br />
                        <br /> Not missing a single detail, our teams ensure
                        that both front-end and back-end issues are properly
                        addressed for each of the delivered web applications.
                      </p>
                    </div>
                    <div className="sm:block hidden">
                      <img src="/under.png" alt="" />
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </Zoom>
              <Zoom when={show} duration={800}>
                {selectedIndex === "Copy writing" ? (
                  <div className="grid sm:grid-cols-2  gap-8">
                    <div className="sm:hidden ">
                      <img
                        src="/under.png"
                        alt=""
                        className="h-[150px] w-[200px] m-auto"
                      />
                    </div>
                    <div>
                      <p className="text-white xl:text-[18px] text-[16px]  myfont font-bold mt-[20px]">
                        Not missing a single detail, our teams ensure that both
                        front-end and back-end issues are properly addressed for
                        each of the delivered web applications.
                        <br />
                        <br /> Not missing a single detail, our teams ensure
                        that both front-end and back-end issues are properly
                        addressed for each of the delivered web applications.
                      </p>
                    </div>
                    <div className="sm:block hidden">
                      <img src="/under.png" alt="" />
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </Zoom>
              <div className="flex justify-center items-center mt-[39px]">
                <div
                  className={`w-[10px] h-[10px] ${
                    selectedIndex === "Web Development"
                      ? "bg-[#7DE6E6]"
                      : "bg-white"
                  }  rounded-full `}
                />
                <div
                  className={`w-[10px] h-[10px] ${
                    selectedIndex === "Ui / Ux designer"
                      ? "bg-[#7DE6E6]"
                      : "bg-white"
                  }  rounded-full ml-[19px]`}
                />
                <div
                  className={`w-[10px] h-[10px] ${
                    selectedIndex === "SEO Research"
                      ? "bg-[#7DE6E6]"
                      : "bg-white"
                  }  rounded-full ml-[19px]`}
                />
                <div
                  className={`w-[10px] h-[10px] ${
                    selectedIndex === "Copy writing"
                      ? "bg-[#7DE6E6]"
                      : "bg-white"
                  }  rounded-full ml-[19px]`}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
