import React from "react";

export default function ServicesSlider({ item }) {
  return (
    <div>
      <div className="grid sm:grid-cols-2  gap-8">
        <div className="sm:hidden ">
          <img src="/under.png" alt="" className="h-[150px] w-[200px] m-auto" />
        </div>
        <div>
          <p className="text-[#058D8D] 2xl:text-[35px] xl:text-[30px] text-[20px] myfont font-bold ml-[20px]">
            {item}
          </p>

          <p className="text-white 2xl:text-[25px] xl:text-[19px] text-[16px]  myfont font-bold mt-[20px]">
            Not missing a single detail, our teams ensure that both front-end
            and back-end issues are properly addressed for each of the delivered
            web applications.
            <br />
            <br /> Not missing a single detail, our teams ensure that both
            front-end and back-end issues are properly addressed for each of the
            delivered web applications.
          </p>
        </div>
        <div className="sm:block hidden">
          <img src="/under.png" alt="" />
        </div>
      </div>
    </div>
  );
}
