import React from "react";
import { Tilt } from "react-tilt";

const defaultOptions = {
  reverse: false, // reverse the tilt direction
  max: 35, // max tilt rotation (degrees)
  perspective: 1000, // Transform perspective, the lower the more extreme the tilt gets.
  scale: 1.1, // 2 = 200%, 1.5 = 150%, etc..
  speed: 1000, // Speed of the enter/exit transition
  transition: true, // Set a transition on enter/exit.
  axis: null, // What axis should be disabled. Can be X or Y.
  reset: true, // If the tilt effect has to be reset on exit.
  easing: "cubic-bezier(.03,.98,.52,.99)", // Easing on enter/exit.
};
export default function About() {
  return (
    <div>
      <div id="AboutUS" className="bg-black sm:pt-[120px] pt-[60px] px-[20px]">
        <div className=" max-w-[1660px] w-[100%] m-auto">
          <p className="sm:pl-[111px] sm:text-start text-center text-[#7DE6E6] 2xl:text-[30px] font-bold text-[30px] myfont">
            About US
          </p>

          <div
            className="xl:w-[1000px] lg:w-[900px] w-[100%] m-auto py-[30px] px-[30px] rounded-[30px] mt-5"
            style={{
              backgroundImage:
                "linear-gradient(97deg, rgba(160, 248, 248, 0.05) 6.81%, rgba(84, 90, 90, 0.09) 53.89%)",
              backdropFilter: "blur(10px)",
            }}
          >
            <p className="text-[#7DE6E6] sm:text-[35px] text-[25px] font-bold">
              We solve troubles our clients have
            </p>
            <p className="2xl:text-[20px] text-[16px] mt-[22px] myfont">
              Our experience gives us the opportunity to select the right
              candidates for you in a short time, who will work effectively,
              honestly, and transparently.
            </p>
          </div>

          <div className="xl:px-[100px] px-[20px]  sm:flex hidden flex-wrap sm:flex-row flex-col justify-between items-center">
            <Tilt options={defaultOptions}>
              <div
                className="w-[250px] py-[17px] rounded-[30px]  cursor-pointer sm:mr-4 mt-[50px]"
                style={{
                  backgroundImage:
                    "linear-gradient(97deg, rgba(160, 248, 248, 0.05) 6.81%, rgba(84, 90, 90, 0.09) 53.89%)",
                  backdropFilter: "blur(10px)",
                }}
              >
                <img src="/Group 1.svg" alt="" className="m-auto w-[120px]" />
                <p className="text-[#058D8D] text-center text-[20px] font-bold">
                  Innovation Architect
                </p>
                <p className="text-[18px] text-[#7DE6E6] myfont text-center">
                  Shivam
                </p>
                <p className="myfont text-[14px] px-[10px] mt-3">
                  Our Founder, a visionary developer, leads our journey,
                  transforming dreams into tech-driven realities for your
                  success.
                </p>
              </div>
            </Tilt>
            <Tilt options={defaultOptions}>
              <div
                className="w-[250px] py-[17px] rounded-[30px]  cursor-pointer sm:mr-4 mt-[50px] "
                style={{
                  backgroundImage:
                    "linear-gradient(97deg, rgba(160, 248, 248, 0.05) 6.81%, rgba(84, 90, 90, 0.09) 53.89%)",
                  backdropFilter: "blur(10px)",
                }}
              >
                <img
                  src="/Group 1 (1).svg"
                  alt=""
                  className="m-auto w-[120px]"
                />
                <p className="text-[#058D8D] text-center text-[20px] font-bold">
                  Pixel Perfectionist
                </p>
                <p className="text-[18px] text-[#7DE6E6] myfont text-center">
                  Jaskaran
                </p>
                <p className="myfont text-[14px] px-[10px] mt-3">
                  Our UI/UX Designer creates seamless experiences, enhancing
                  user satisfaction and your brand's success.
                </p>
              </div>
            </Tilt>
            <Tilt options={defaultOptions}>
              <div
                className="w-[250px] py-[17px] rounded-[30px]  cursor-pointer sm:mr-4 mt-[50px]"
                style={{
                  backgroundImage:
                    "linear-gradient(97deg, rgba(160, 248, 248, 0.05) 6.81%, rgba(84, 90, 90, 0.09) 53.89%)",
                  backdropFilter: "blur(10px)",
                }}
              >
                <img
                  src="/Group 1 (2).svg"
                  alt=""
                  className="m-auto w-[120px]"
                />
                <p className="text-[#058D8D] text-center text-[20px] font-bold">
                  Google Guru
                </p>
                <p className="text-[18px] text-[#7DE6E6] myfont text-center">
                  Gurmail
                </p>
                <p className="myfont text-[14px] px-[10px] mt-3">
                  Our SEO Expert optimizes your online presence, driving organic
                  traffic and boosting your digital success.
                </p>
              </div>
            </Tilt>
            <Tilt options={defaultOptions}>
              <div
                className="w-[250px] py-[17px] rounded-[30px]  cursor-pointer sm:mr-4 mt-[50px] "
                style={{
                  backgroundImage:
                    "linear-gradient(97deg, rgba(160, 248, 248, 0.05) 6.81%, rgba(84, 90, 90, 0.09) 53.89%)",
                  backdropFilter: "blur(10px)",
                }}
              >
                <img
                  src="/Group 1 (3).svg"
                  alt=""
                  className="m-auto w-[120px]"
                />
                <p className="text-[#058D8D] text-center text-[20px] font-bold">
                  Craftsman
                </p>
                <p className="text-[18px] text-[#7DE6E6] myfont text-center">
                  Munish
                </p>
                <p className="myfont text-[14px] px-[10px] mt-3">
                  Our developer crafts digital solutions, turning ideas into
                  innovative web experiences for your business's success
                </p>
              </div>
            </Tilt>
          </div>
          <div className="xl:px-[100px] px-[20px]  sm:hidden flex mt-10 flex-wrap  flex-col justify-center items-center">
            <div
              className="w-[250px] py-[17px] rounded-[30px]  cursor-pointer sm:mr-4 mt-[50px  m-auto]"
              style={{
                backgroundImage:
                  "linear-gradient(97deg, rgba(160, 248, 248, 0.05) 6.81%, rgba(84, 90, 90, 0.09) 53.89%)",
                backdropFilter: "blur(10px)",
              }}
            >
              <img src="/Group 1.svg" alt="" className="m-auto w-[120px]" />
              <p className="text-[#058D8D] text-center text-[20px] font-bold">
                Innovation Architect
              </p>
              <p className="text-[18px] text-[#7DE6E6] myfont text-center">
                Shivam
              </p>
              <p className="myfont text-[14px] px-[10px] mt-3">
                Our Founder, a visionary developer, leads our journey,
                transforming dreams into tech-driven realities for your success.
              </p>
            </div>
            <div
              className="w-[250px] py-[17px] rounded-[30px]  cursor-pointer sm:mr-4 mt-[50px] "
              style={{
                backgroundImage:
                  "linear-gradient(97deg, rgba(160, 248, 248, 0.05) 6.81%, rgba(84, 90, 90, 0.09) 53.89%)",
                backdropFilter: "blur(10px)",
              }}
            >
              <img src="/Group 1 (1).svg" alt="" className="m-auto w-[120px]" />
              <p className="text-[#058D8D] text-center text-[20px] font-bold">
                Pixel Perfectionist
              </p>
              <p className="text-[18px] text-[#7DE6E6] myfont text-center">
                Jaskaran
              </p>
              <p className="myfont text-[14px] px-[10px] mt-3">
                Our UI/UX Designer creates seamless experiences, enhancing user
                satisfaction and your brand's success.
              </p>
            </div>
            <div
              className="w-[250px] py-[17px] rounded-[30px]  cursor-pointer sm:mr-4 mt-[50px]"
              style={{
                backgroundImage:
                  "linear-gradient(97deg, rgba(160, 248, 248, 0.05) 6.81%, rgba(84, 90, 90, 0.09) 53.89%)",
                backdropFilter: "blur(10px)",
              }}
            >
              <img src="/Group 1 (2).svg" alt="" className="m-auto w-[120px]" />
              <p className="text-[#058D8D] text-center text-[20px] font-bold">
                Google Guru
              </p>
              <p className="text-[18px] text-[#7DE6E6] myfont text-center">
                Gurmail
              </p>
              <p className="myfont text-[14px] px-[10px] mt-3">
                Our SEO Expert optimizes your online presence, driving organic
                traffic and boosting your digital success.
              </p>
            </div>
            <div
              className="w-[250px] py-[17px] rounded-[30px]  cursor-pointer sm:mr-4 mt-[50px] "
              style={{
                backgroundImage:
                  "linear-gradient(97deg, rgba(160, 248, 248, 0.05) 6.81%, rgba(84, 90, 90, 0.09) 53.89%)",
                backdropFilter: "blur(10px)",
              }}
            >
              <img src="/Group 1 (3).svg" alt="" className="m-auto w-[120px]" />
              <p className="text-[#058D8D] text-center text-[20px] font-bold">
                Craftsman
              </p>
              <p className="text-[18px] text-[#7DE6E6] myfont text-center">
                Munish
              </p>
              <p className="myfont text-[14px] px-[10px] mt-3">
                Our developer crafts digital solutions, turning ideas into
                innovative web experiences for your business's success
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
